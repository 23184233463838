/* RunJobForm.css */

.form-label {
    display: block;
    margin-bottom: 10px;
  }
  
.date-picker {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    z-index: 1000; /* Add a z-index to make sure the datepicker appears above other elements */
}

.submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.data-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    padding: 20px;
}

.data-table th, .data-table td {
padding: 10px;
border-bottom: 1px solid #ddd;
}

.table-wrapper {
    overflow-y: auto;
    max-height: 55vh; /* Adjust this value to change the maximum height of the table */
    border: 1px solid #ddd; /* Add a border around the table */
    width: 90%; /* Adjust this value to change the width of the table */
    margin: 0 auto; /* Center the table on the page */
}

.sticky-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.date-picker-wrapper,
.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.submit-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.button-group label {
    border: 1px solid #000;
    padding: 5px;
    margin: 0 5px;
    border-radius: 5px;
}

.download-button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
}

.checkbox-label {
    position: relative;
    display: inline-block;
    border: 1px solid #000;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    width: 15vw;
}
  
.checkbox-label input[type="checkbox"] {
    display: none;
}
  
.checkbox-label span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    z-index: 1;
}
  
.checkbox-label input[type="checkbox"]:checked + span {
    color: #fff; 
}
  
.checkbox-label input[type="checkbox"]:checked + span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #088877;
    z-index: -1;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}