.App {
  text-align: center;
}
header {
  background-color: #f8f9fa;
  padding: 10px 0;
}

.nav-tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: space-around;
  border: 1px solid #000;
  border-radius: 5px;
}

.nav-tab {
  flex: 1;
  padding: 5px 20px;
  transition: background-color 0.3s ease;
}

.nav-tab a {
  text-decoration: none;
  color: #333;
  display: block; /* Make the link take up the full space of the tab */
}

.nav-tab.active {
  background-color: #088877; /* Move the background color to the tab */
}

.nav-tab.active a {
  color: #ffffff;
}

.header-title {
  text-align: center;
  font-size: 2em;
  margin: 20px;
}

.nav-bar {
  border-radius: 5px;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
}

.admin-link {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}